.about-content-container {
  padding: 10px;
  p {
    font-size: 1.35rem;
    margin-bottom: 12px;
  }
  a {
    color: var(--main-color);
    font-weight: 700;
  }
}