:root {
  --main-bg-color: #66a49f;
  --main-color: #66a49f;
  --secondary-bg-color: #e5e5e5;
  --secondary-color: #718096;
  --green-01: #66A49F;
  --green-02: #68B9A2;
  --green-03: #7ACD9D;
  --green-04: #9ADE90;
  --green-05: #C5ED80;
  --green-06: #F9F871;
  --brown-01: #F3EADA;
  --brown-02: #CFCEB8;
  --brown-03: #A9B49A;
  --brown-04: #7F9B81;
  --brown-05: #54826E;
  --brown-06: #236960;
  --main-gray: #eeeeee;

  --boxShadow: "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)";
}
