//This css remove focus
*:focus {
  box-shadow: none !important;
  outline: none;
}

*[data-focus] {
  box-shadow: none !important;
  outline: none;
}
.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

html {
  font-size: 7px;
  @media screen and (min-width: 280px) {
    font-size: 7.5px;
  }
  @media screen and (min-width: 320px) {
    font-size: 8.5px;
  }
  @media screen and (min-width: 375px) {
    font-size: 10px;
  }
}

.animation-input {
  height: 5rem;
  position: relative;
  overflow: hidden;
  padding-top: 1.5rem;
}
.animation-input input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.animation-input input::placeholder,
.animation-input input::-webkit-input-placeholder {
  visibility: hidden;
}
.animation-input label {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0%;
  pointer-events: none;
  border-bottom-width: 1px;
  font-size: 1.6rem;
}
.animation-input label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #5fa8d3;
  bottom: -1px;
  left: 0px;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.6);
}
.animation-input input:focus + .label-name .content-name,
.animation-input input:valid + .label-name .content-name {
  transform: translateY(-110%);
  font-size: 1.5rem;
  color: #5fa8d3;
}
.animation-input input:focus + .label-name::after,
.animation-input input:valid + .label-name::after {
  transform: translateX(0%);
}

.animation-input input:focus {
  &::placeholder {
    visibility: visible;
  }
}

.animation-input label#invalid::after {
  border-color: red;
}

//@at-root

.animation-area {
  height: 17rem;
  position: relative;
  overflow: hidden;
  padding-top: 1.5rem;
}
.animation-area textarea {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}
.animation-area textarea::placeholder,
.animation-area textarea::-webkit-textarea-placeholder {
  visibility: hidden;
}
.animation-area label {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0%;
  pointer-events: none;
  border-bottom-width: 1px;
  font-size: 1.6rem;
}
.animation-area label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #5fa8d3;
  bottom: -2px;
  left: 0px;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.content-name {
  position: absolute;
  top: 20px;
  left: 0px;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.6);
}
.animation-area textarea:focus + .label-name .content-name {
  transform: translateY(-15%);
  font-size: 1.5rem;
  color: #5fa8d3;
}
.animation-area textarea:valid + .label-name .content-name {
  transform: translateY(-15%);
  font-size: 1.5rem;
}
.animation-area textarea:focus + .label-name::after {
  transform: translateX(0%);
  border-color: #5fa8d3 !important;
}

.animation-area textarea:valid + .label-name::after {
  transform: translateX(0%);
  border-color: transparent;
}

.animation-area textarea:focus {
  &::placeholder {
    visibility: visible;
  }
}

.language-arduino {
  font-size: 1.3rem !important;
}

.no-select-text {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.input-check-box span {
  width: 20px;
  height: 20px;
  font-size: 20px;
  border-color: grey;
}

.input-check-box svg {
  font-size: 15px;
}