.public-project-content {
  &__container {
    margin-top: 12px;
    h2 {
      color: var(--main-color);
    }
    #heading-2 {
      font-size: 3rem;
      font-weight: 700;
    }
    #heading-3 {
      font-size: 2.75rem;
      font-weight: 700;
    }
    p {
      font-size: 2rem;
      margin-bottom: 4px;
      font-weight: 400;
    }
  }
}