@font-face {
  font-family: "OpenSans";
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("../fonts/OpenSans-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans";
  font-weight: 500;
  src: local("Open Sans Medium"), local("OpenSans-Medium"),
    url("../fonts/OpenSans-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans";
  font-weight: 600; // Semi-bold
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url("../fonts/OpenSans-SemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "OpenSans";
  font-weight: 700; // Bold
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("../fonts/OpenSans-Bold.woff2") format("woff2");
}
